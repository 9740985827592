import { GenerateKeysComponent } from "./generate-keys/generate-keys.component";
import { AdminHardwareSerialsComponent } from "./hardware-serials/hardware-serials.component";
import { AdminLicenseKeyImportComponent } from "./license-key-import/license-key-import.component";
import { ProductCategoriesComponent } from "./product-categories/product-categories.component";
import { AdminProductListComponent } from "./product-list/product-list.component";

export const ADMINPORTAL_COMPONENTS = [GenerateKeysComponent, AdminProductListComponent, ProductCategoriesComponent, AdminHardwareSerialsComponent, AdminLicenseKeyImportComponent];

export * from "./generate-keys/generate-keys.component";
export * from "./product-list/product-list.component";
export * from "./hardware-serials/hardware-serials.component";
export * from "./product-categories/product-categories.component";
export * from "./license-key-import/license-key-import.component";
