<div class="row">
  <div class="col-md-12">
    <div class="d-flex">
      <myp-segmented-bar [white]="true" (selected)="selectSection($event.id)" [options]="sections"></myp-segmented-bar>
      <button class="fa-button m-l-10" placement="bottom" [ngbTooltip]="'Add New Product'" (click)="create()">
        <fa-icon icon="plus" class="action create-action circle-icon-btn"></fa-icon>
      </button>
    </div>

    <myp-inline-loader [loading]="loading" height="400px" spinnerName="loading"></myp-inline-loader>
    <div class="m-t-20" *ngIf="!loading && products && products.length">
      <myp-product-filter (filterChanged)="filterProducts($event)" (sortChanged)="sortProducts()" [showSortType]="false" [showListGrid]="false"></myp-product-filter>
      <div *ngFor="let product of displayedProducts" class="m-b-10 m-t-10">
        <myp-list-item
          (itemClicked)="go(product.id)"
          [metadata]="product.displayMetadata"
          [href]="'/products/detail/' + product.id"
          [photo]="product.imageUrl ? product.imageUrl : ''"
          [showImage]="product.imageUrl"
          [title]="product.title"
          [imageRound]="false"
        >
          <div class="button-content" *ngIf="!product.metadata?.categories?.length">
            <button
              type="button"
              class="circle-icon-btn m-r-10 bg-transparent border-0 p-0"
              placement="left"
              [ngbTooltip]="'Categorize This Product'"
              (click)="showModal(addCategoryModal, product, $event)"
            >
              <fa-icon icon="layer-group" class="action" aria-hidden="true"></fa-icon>
              <span class="sr-only">Categorize Product</span>
            </button>
          </div>
        </myp-list-item>
        <hr class="m-y-5" />
      </div>
      <myp-paginator
        *ngIf="!filtering"
        [offset]="pageOffset + 1"
        [limit]="pageLimit"
        [resultsLength]="displayedProducts.length"
        [totalPages]="totalPages"
        [totalResults]="products.length"
        [paginate]="paginate"
        [async]="false"
      ></myp-paginator>
      <!-- <ng-container *ngIf="selectedSecton == 'bundles'">
        <div *ngFor="let product of filteredProducts" class="m-b-10 m-t-10">
          <myp-list-item [routerLink]="['/products/detail', product.id]" [showImage]="false" [title]="product.title"></myp-list-item>
          <hr class="m-y-5" />
        </div>
      </ng-container> -->
    </div>
  </div>
</div>

<ng-template #addCategoryModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-password-update">Add Category for {{ categorizingProduct.title }}</h4>
    <button ngbAutofocus class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="product-category" *ngFor="let cat of productService.productCategories">
      <button class="category-name small-btn" (click)="selectCategory(cat.string_id)">
        {{ cat.title }}
      </button>
      <div class="subcategories">
        <div class="product-subcategory" *ngFor="let subcat of cat.children">
          <button class="subcategory-name small-btn" (click)="selectCategory(subcat.string_id)">
            {{ subcat.title }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
