import { Location } from "@angular/common";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { BaseComponent, UserService, ProductService, Product, SimpleProduct, AdminService, WindowService, ContentService, environment } from "@mypxplat/xplat/core";
import { AppService } from "@mypxplat/xplat/web/core";
import { format } from "date-fns";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { AdminAddNewProductComponent } from "../../modals";
import { FileUploader } from "ng2-file-upload";

@Component({
  selector: "myp-admin-license-key-import",
  templateUrl: "license-key-import.component.html",
})
export class AdminLicenseKeyImportComponent extends BaseComponent implements OnInit {
  public loading: boolean;
  public data: any;
  public provider: string = "-1";
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public uploadProgress: number;
  public keyType: string;
  constructor(
    userService: UserService,
    public productService: ProductService,
    public adminService: AdminService,
    public appService: AppService,
    private _activatedRoute: ActivatedRoute,
    public router: Router,
    public location: Location,
    private _win: WindowService,
    public contentService: ContentService
  ) {
    super(userService);
  }

  ngOnInit() {
    // check the router url for oem. if the path is /products/oem_import, set the keyType to oem.
    if (this.router.url.includes("oem")) {
      this.keyType = "oem";
    } else if (this.router.url.includes("license_import")) {
      this.keyType = "tpl";
    }
    this.loading = true;
    let dataEndpoint = "product/oemkeys";
    if (this.keyType === "tpl") dataEndpoint = "product/third-party-license";
    this.adminService.get(dataEndpoint).subscribe((data) => {
      this.loading = false;
      this.data = data;
      console.log(data);
      if (this.data.products) {
        this.data.flatProducts = [...this.data.products.software, ...this.data.products.extensions].sort((a, b) => (a.title > b.title ? 1 : -1));
      }
    });
  }

  public uploadData: any;
  providerChange(args) {
    if (args != "-1") {
      this.uploadData = {
        bucket: environment.licenseKeyImportBucket,
        filename: this.keyType + "__" + args + "__" + new Date().toISOString() + ".csv",
      };
      this.adminService.post("create-upload-url", this.uploadData).subscribe((url: string) => {
        this.uploader = new FileUploader({
          isHTML5: true,
          method: "PUT",
          queueLimit: 1,
          autoUpload: false,
          disableMultipart: true,
          url: url,
        });

        this.uploader.onAfterAddingFile = (f) => {
          f.withCredentials = false;
          this.uploadingFile = f;

          this.contentService.uploadSession.filesMap[this.uploadingFile.file.name] = { file: this.uploadingFile };
        };
        this.uploader.onProgressAll = (progress: any) => {
          this.uploadProgress = progress || 1;
          if (this.contentService.uploadSession.filesMap[this.uploadingFile.file.name]) {
            this.contentService.uploadSession.filesMap[this.uploadingFile.file.name].progress = progress;
          }
        };
        this.uploader.onCompleteAll = () => {
          this.uploadingComplete = true;
        };
      });
    }
  }

  clearFile() {
    this.uploader.clearQueue();
    this.uploadingComplete = false;
    this.uploadingFile = undefined;
    this.hasBaseDropZoneOver = false;
  }

  upload() {
    this.uploader.queue.forEach((file) => {
      let uploadHeaders = [];
      uploadHeaders.push({ name: "Content-Type", value: this.uploadingFile.type });
      uploadHeaders.push({ name: "Content-Disposition", value: 'attachment; filename="' + this.uploadData.filename + '"' }); // this is breaking upload for some filenames that are non ASCII standard
      file.headers = uploadHeaders;
      this.uploader.uploadItem(file);
      // this.createdContentPackageItem = result.file;
    });
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }
}
