<div class="modal-header" #createTicket>
  <h1 id="modal-title" class="modal-title">{{ "support.create_ticket" | translate }}</h1>
  <button ngbAutofocus class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="createTicketLoader"></myp-inline-loader>

  <div *ngIf="!loading && !ticketResult">
    <div class="form-group">
      <label class="form-text mb-0" for="subject">{{ "support.subject" | translate }}*</label>
      <input type="text" class="form-control" [(ngModel)]="ticketArgs.subject" id="subject" required />
    </div>

    <div class="form-group">
      <label class="form-text mb-0" for="description">{{ "support.description" | translate }}*</label>
      <textarea class="form-control" [(ngModel)]="ticketArgs.body" id="description" required></textarea>
    </div>
    <div class="form-group">
      <label class="form-text mb-0" for="country">{{ "support.choose_country" | translate }}*</label>
      <input
        type="text"
        name="country"
        class="form-control"
        placeholder="{{ 'support.filter_country' | translate }}"
        (focus)="focusInput('country')"
        (blur)="inputBlur('country')"
        (keyup)="searchCountries($event)"
        [(ngModel)]="selectedCountry"
        id="country"
        required
        role="combobox"
        aria-autocomplete="list"
        [attr.aria-expanded]="searchingCountries"
        aria-controls="country-listbox"
        aria-haspopup="country-listbox"
        [attr.aria-activedescendant]="selectedCountry ?? ''"
      />
    </div>
    <div style="position: relative">
      <ul id="country-listbox" role="listbox" *ngIf="searchingCountries" class="ticketfield-list">
        <li *ngFor="let country of filteredCountries" role="option" [id]="country" (click)="selectCountry(country)" class="ticketfield-option">
          <span>{{ country }}</span>
        </li>
      </ul>
    </div>

    <div class="form-group">
      <label class="form-text mb-0" for="product">{{ "support.choose_product" | translate }}*</label>
      <input
        type="text"
        name="product"
        class="form-control"
        placeholder="{{ 'support.filter_products' | translate }}"
        (focus)="focusInput('product')"
        (blur)="inputBlur('product')"
        (keyup)="searchProducts($event)"
        [(ngModel)]="selectedProduct"
        id="product"
        required
        role="combobox"
        aria-autocomplete="list"
        [attr.aria-expanded]="searchingProducts"
        aria-controls="products-listbox"
        aria-haspopup="products-listbox"
        [attr.aria-activedescendant]="selectedProduct ?? ''"
      />
    </div>
    <div style="position: relative">
      <ul id="products-listbox" role="listbox" *ngIf="searchingProducts" class="ticketfield-list">
        <li *ngFor="let product of filteredProducts" role="option" [id]="product" (click)="selectProduct(product)" class="ticketfield-option">
          <span>{{ product }}</span>
        </li>
      </ul>
    </div>

    <div class="form-group">
      <label class="form-text mb-0" for="os">{{ "support.choose_os" | translate }}*</label>
      <input
        type="text"
        name="os"
        class="form-control"
        placeholder="{{ 'support.filter_os' | translate }}"
        (focus)="focusInput('os')"
        (blur)="inputBlur('os')"
        (keyup)="searchOperatingSystems($event)"
        [(ngModel)]="selectedOperatingSystem"
        id="os"
        required
        role="combobox"
        aria-autocomplete="list"
        [attr.aria-expanded]="searchingOperatingSystems"
        aria-controls="operating-systems-listbox"
        aria-haspopup="cooperating-systemsuntry-listbox"
        [attr.aria-activedescendant]="selectedOperatingSystem ?? ''"
      />
    </div>
    <div style="position: relative">
      <ul id="operating-systems-listbox" role="listbox" *ngIf="searchingOperatingSystems" class="ticketfield-list">
        <li *ngFor="let os of filteredOperatingSystems" role="option" [id]="os" (click)="selectOperatingSystem(os)" class="ticketfield-option">
          <span>{{ os }}</span>
        </li>
      </ul>
    </div>

    <div class="form-group">
      <label class="form-text mb-0" for="ware">{{ "support.choose_ware" | translate }}*</label>
      <input
        type="text"
        name="ware"
        class="form-control"
        placeholder="{{ 'support.filter_ware' | translate }}"
        (focus)="focusInput('ware')"
        (blur)="inputBlur('ware')"
        (keyup)="searchAudioSoftware($event)"
        [(ngModel)]="selectedAudioSoftware"
        id="ware"
        required
        role="combobox"
        aria-autocomplete="list"
        [attr.aria-expanded]="searchingAudioSoftware"
        aria-controls="audio-software-listbox"
        aria-haspopup="audio-software-listbox"
        [attr.aria-activedescendant]="selectedAudioSoftware ?? ''"
      />
    </div>
    <div style="position: relative">
      <ul id="audio-software-listbox" role="listbox" *ngIf="searchingAudioSoftware" class="ticketfield-list">
        <li *ngFor="let ware of filteredAudioSoftware" role="option" [id]="ware" (click)="selectAudioSoftware(ware)" class="ticketfield-option">
          <span>{{ ware }}</span>
        </li>
      </ul>
    </div>

    <div class="form-group">
      <label class="form-text mb-0" for="attachment">Add Attachments (20MB filesize limit)</label>
      <input type="file" ng2FileSelect [uploader]="uploader" id="attachment" />
      <!-- <div>
          <small>Accepted file types: png, jpg, gif, pdf, doc, docx, odt, txt, xml, json, wav, mp4, mov</small>
        </div> -->
    </div>

    <div role="status" *ngIf="uploader && uploader.queue.length && uploader.isUploading">
      <div *ngFor="let item of uploader.queue" class="text-center" style="position: relative">
        <span style="color: gray; font-size: 16px; text-align: center; font-family: Lato; font-weight: bold">{{ "general.uploading" | translate }} {{ item?.file?.name }}</span>
        <br />
        <br />
        <div style="display: inline-block; margin: auto">
          <round-progress
            [current]="item.progress"
            max="100"
            [color]="'#45ccce'"
            [background]="'#eaeaea'"
            [radius]="100"
            [stroke]="10"
            [semicircle]="false"
            [rounded]="true"
            [clockwise]="true"
            [responsive]="false"
            [duration]="250"
            [animation]="'easeInOutQuart'"
            [animationDelay]="0"
          ></round-progress>
        </div>
        <br />
        <span *ngIf="!uploadingComplete" class="uploading-label">{{ item?.progress }}%</span>
        <span *ngIf="uploadingComplete && !fileResponse" class="uploading-label">{{ "general.processing_dots" | translate }}</span>
      </div>
    </div>

    <div *ngIf="attachments && attachments.length">
      <label class="form-text mb-0">Attachments</label>
      <ul class="list-group">
        <li class="list-group-item p-0 bg-transparent border-0" *ngFor="let attachment of attachments">
          {{ attachment.upload && attachment.upload.attachment && attachment.upload.attachment.file_name ? attachment.upload.attachment.file_name : "" }}
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="ticketResult" class="text-center" role="status">
    <fa-icon icon="check-circle" style="color: green; font-size: 55px"></fa-icon>
    <br />
    <br />
    {{ "support.ticket_success" | translate }}
    <br />
    <br />
    <button type="button" class="btn btn-primary" (click)="viewTicket()">{{ "support.view_ticket" | translate }}</button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-primary" *ngIf="!ticketResult" (click)="submit()">{{ "general.submit" | translate }}</button>
  <button type="button" class="btn btn-blank" (click)="cancel()">{{ "general.close" | translate }}</button>
</div>
