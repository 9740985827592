<div class="modal-header">
  <h4 class="modal-title">{{ "product_detail.previous_versions" | translate }}</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus *ngIf="versions && versions.length">
  <select class="form-control" [(ngModel)]="selectedVersion">
    <option *ngFor="let version of versions" [ngValue]="version">{{ version.version_number }}</option>
  </select>
  <br />
  <br />

  <div>
    <div class="m-b-20" *ngIf="selectedVersion.mac_url">
      <myp-list-item
        [title]="selectedVersion.version_number"
        [showImage]="false"
        [icon]="['fab', 'apple']"
        [subData]="['Max OSX']"
        [item]="selectedVersion"
        [includeChevron]="false"
        [showBackground]="true"
        (itemClicked)="download(selectedVersion.mac_url)"
      >
        <div class="button-content">
          <button class="fa-button" (click)="download(selectedVersion.mac_url); $event.stopPropagation()">
            <fa-icon icon="download" placement="top" [ngbTooltip]="'Download'" class="action circle-icon-btn m-r-5"></fa-icon>
            <span class="sr-only">Download</span>
          </button>
        </div>
      </myp-list-item>
    </div>
    <div class="m-b-20" *ngIf="selectedVersion.win_url">
      <myp-list-item
        [title]="selectedVersion.version_number"
        [showImage]="false"
        [icon]="['fab', 'windows']"
        [subData]="['Windows']"
        [item]="selectedVersion"
        [includeChevron]="false"
        [showBackground]="true"
        (itemClicked)="download(selectedVersion.win_url)"
      >
        <div class="button-content">
          <button class="fa-button" (click)="download(selectedVersion.win_url); $event.stopPropagation()">
            <fa-icon icon="download" placement="top" [ngbTooltip]="'Download'" class="action circle-icon-btn m-r-5"></fa-icon>
            <span class="sr-only">Download</span>
          </button>
        </div>
      </myp-list-item>
    </div>

    <div class="m-b-20" *ngIf="selectedVersion.win64_url">
      <myp-list-item
        [title]="selectedVersion.version_number"
        [showImage]="false"
        [icon]="['fab', 'windows']"
        [subData]="['Windows (64 bit)']"
        [item]="selectedVersion"
        [includeChevron]="false"
        [showBackground]="true"
        (itemClicked)="download(selectedVersion.win64_url)"
      >
        <div class="button-content">
          <button class="fa-button" (click)="download(selectedVersion.win64_url); $event.stopPropagation()">
            <fa-icon icon="download" placement="top" [ngbTooltip]="'Download'" class="action circle-icon-btn m-r-5"></fa-icon>
            <span class="sr-only">Download</span>
          </button>
        </div>
      </myp-list-item>
    </div>

    <div class="m-b-20" *ngIf="selectedVersion.linux_url">
      <myp-list-item
        [title]="selectedVersion.version_number"
        [showImage]="false"
        [icon]="['fab', 'linux']"
        [subData]="['Linux']"
        [item]="selectedVersion"
        [includeChevron]="false"
        [showBackground]="true"
        (itemClicked)="download(selectedVersion.linux_url)"
      >
        <div class="button-content">
          <button class="fa-button" (click)="download(selectedVersion.linux_url); $event.stopPropagation()">
            <fa-icon icon="download" placement="top" [ngbTooltip]="'Download'" class="action circle-icon-btn m-r-5"></fa-icon>
            <span class="sr-only">Download</span>
          </button>
        </div>
      </myp-list-item>
    </div>

    <div class="m-b-20" *ngIf="selectedVersion.flatpak_url">
      <myp-list-item
        [title]="selectedVersion.version_number"
        [showImage]="false"
        [icon]="['fab', 'linux']"
        [subData]="['Flatpak']"
        [item]="selectedVersion"
        [includeChevron]="false"
        [showBackground]="true"
        (itemClicked)="download(selectedVersion.flatpak_url)"
      >
        <div class="button-content">
          <button class="fa-button" (click)="download(selectedVersion.flatpak_url); $event.stopPropagation()">
            <fa-icon icon="download" placement="top" [ngbTooltip]="'Download'" class="action circle-icon-btn m-r-5"></fa-icon>
            <span class="sr-only">Download</span>
          </button>
        </div>
      </myp-list-item>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
