<div class="chat-container" [class.comments]="commentsUI" [style]="fullHeight ? 'height: 100%' : ''">
  <ul class="messages" #chatWindow [ngStyle]="{ height: height ? height + 'px' : '100%' }">
    <li *ngFor="let msg of messages">
      <ng-container *ngIf="participants && participants.length && participantMap[msg.user_id]">
        <div class="msg-container" [class.me]="msg.user_id == user.id" [class.them]="msg.user_id != user.id">
          <img [src]="participantMap[msg.user_id].photo || './assets/images/default_avatar.jpg'" class="profile-photo" />
          <div class="msg">
            <div class="text" [innerHTML]="msg.htmlMessage"></div>
            <span class="timestamp d-block">
              {{ msg.created.toDate() | date: "MMM Do hh:mma" : true }}
              <span *ngIf="msg.user_id != user.id">&nbsp;{{ participantMap[msg.user_id].name }}</span>
              <span *ngIf="msg.edited" class="c-gray">&nbsp;Edited&nbsp;&nbsp;</span>
              <div>
                <button *ngIf="msg.user_id == user.id || admin" class="msg-options-link" (click)="msgOptions(msg)">&nbsp;{{ "sphere.options" | translate }}</button>
              </div>
            </span>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="commentsUI">
        <myp-comment
          [comment]="msg"
          [replies]="parentThreadMap[msg.key]"
          (flagged)="flagComment($event)"
          (unflagged)="unflagComment($event)"
          (liked)="likeComment($event)"
          (replied)="sendReply($event)"
          (editMessageClicked)="editComment($event)"
          (deleteMessageClicked)="deleteComment($event)"
          (flagCleared)="clearFlag($event)"
          (adminDeleteMessageClicked)="adminDeleteComment($event)"
          [forceDark]="forceDark"
        ></myp-comment>
      </ng-container>
    </li>
    <span *ngIf="commentsUI && !messages.length" [class.c-white]="forceDark" style="display: flex; align-items: center; height: 100%; text-align: center; justify-content: center">
      No comments yet. Be the first and add one below!
    </span>
  </ul>
  <div class="input-container text-left">
    <label id="comment-label" class="video-label" for="main-input">Add a comment...</label>
    <div class="msg-input">
      <myp-message-input
        #msgInput
        [type]="'span'"
        inputID="main-input"
        [mentionSource]="mentionDataSource ? true : false"
        [mentionDataSource]="mentionDataSource"
        (valueChanged)="messageValueChange($event)"
        (returnPressed)="sendMessage($event)"
        [placeholder]="commentsUI ? 'Ctrl + Enter to send...' : 'Enter a message...'"
        [includeSendButton]="true"
        ariaLabelBy="comment-label"
      ></myp-message-input>
      <!-- <button class="send-btn" (click)="sendMessage()" [class.disabled]="!newMessage">{{ (commentsUI ? "Submit" : ("general.send" | translate)) | uppercase }}</button> -->
    </div>
  </div>
</div>
