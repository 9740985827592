<div class="m-y-20 text-center">
  <div class="d-flex align-items-center justify-content-center">
    <div style="width: 30px">
      <fa-icon
        class="circle-icon-btn"
        [animation]="loadingPrevious ? 'spin' : undefined"
        [icon]="loadingPrevious ? 'spinner' : 'chevron-left'"
        *ngIf="offset > 1"
        (click)="onPaginate('previous')"
      ></fa-icon>
    </div>
    <small class="d-block m-x-10">
      {{ offset }}
      <ng-container *ngIf="totalPages">of {{ totalPages }}</ng-container>
    </small>

    <div style="width: 30px">
      <fa-icon
        class="circle-icon-btn"
        [animation]="loadingNext ? 'spin' : undefined"
        *ngIf="resultsLength === limit"
        [icon]="loadingNext ? 'spinner' : 'chevron-right'"
        (click)="onPaginate('next')"
      ></fa-icon>
    </div>
  </div>
  <div *ngIf="totalResults">
    <small>({{ totalResults }} Total Results)</small>
  </div>
</div>
