import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";

import { AdminService, BaseComponent, UserService } from "@mypxplat/xplat/core";
import { BetaService } from "@mypxplat/xplat/web/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "myp-add-edit-previous-version",
  templateUrl: "add-edit-previous-version.component.html",
})
export class AddEditPreviousVersionComponent extends BaseComponent implements OnInit {
  @Input() data: any = {};
  @Output() completed: EventEmitter<any> = new EventEmitter();
  public loading: boolean = false;

  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public adminService: AdminService
  ) {
    super(userService);
  }

  ngOnInit() {
    for (var i in this.data) {
      if (this.data[i] && i.indexOf("url") > -1 && this.data[i].indexOf("http") > -1) {
        this.data[i] = this.formatUrl(this.data[i]);
      }
    }
  }

  formatUrl(url) {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;

    // Remove the leading double slashes if present
    const sanitizedPath = pathname.replace(/^\/\//, "");

    // Extract everything after the "/archives/" segment
    const archivesIndex = sanitizedPath.indexOf("archives/");
    if (archivesIndex === -1) throw new Error('URL does not contain "archives/" segment.');

    // Get the substring starting from "archives/" and remove it
    const formattedPath = sanitizedPath.substring(archivesIndex + "archives/".length);

    // Decode any encoded characters (like %20)
    return decodeURIComponent(formattedPath);
  }

  submit() {
    if (this.data.version_number && (this.data.mac_url || this.data.win_url || this.data.win64_url)) {
      this.loading = true;
      this.adminService.addUpdatePreviousVersion(this.data).subscribe((res: any) => {
        this.loading = false;
        this.completed.next(res?.previous_version || this.data);
        this.activeModal.close();
      });
    } else {
      alert("You must fill out a version number (ex: 1.0.0) and at least one download URL (Mac, Windows, or Windows 64-bit)");
    }
  }
}
