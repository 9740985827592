<div id="videooverlay-container" [class.minimized]="minimized" [class.dock-right]="dockRight" [class.dock-left]="!dockRight" [class.landscape]="landscape && !pinnedToBottom" *ngIf="video">
  <!-- <fa-icon (click)="close()" icon="times" class="circle-icon-btn close-btn"></fa-icon> -->

  <div *ngIf="minimized" class="minimized-buttons d-flex justify-content-between">
    <button class="small-btn force-light" (click)="dockRight = !dockRight">Dock {{ dockRight ? "Left" : "Right" }}</button>
    <div class="d-flex">
      <button class="fa-button" (click)="minimized = !minimized" ngbTooltip="Expand Video">
        <fa-icon icon="expand" class="circle-icon-btn m-r-5"></fa-icon>
        <span class="sr-only">Expand Video</span>
      </button>
      <button class="fa-button" (click)="close()" ngbTooltip="Close Video">
        <fa-icon icon="times" class="circle-icon-btn m-r-5"></fa-icon>
        <span class="sr-only">Close Video</span>
      </button>
    </div>
  </div>

  <div id="videooverlay" #videoOverlay *ngIf="!loadingComments" role="dialog" aria-modal="true" aria-label="Video player">
    <div style="height: 100%; width: 100%">
      <ng-container *ngIf="shouldShowVideo">
        <div style="height: 100%; width: 100%" *ngIf="video.type == 'youtube'">
          <youtube-player [playerVars]="playerVars" height="100%" width="100%" #ytPlayer [videoId]="video.id"></youtube-player>
        </div>
        <iframe *ngIf="video.type == 'vimeo'" [src]="url | safe" width="100%" height="100%" id="vimeo_frame" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </ng-container>
      <ng-container *ngIf="!shouldShowVideo">
        <div class="h-100 d-flex justify-content-center align-items-center text-center">
          <div style="max-width: 600px; margin: 10px">
            <div
              style="
                background-image: url(https://pae-web.presonusmusic.com/uploads/products/media/images/presonus-sphere-website-0321-07.png);
                background-size: cover;
                background-position: center;
                padding: 150px 50px;
                border-radius: 20px;
              "
            >
              <h2 class="brandfont-bold c-white" style="text-shadow: 0px 0px 5px black">{{ "newdash.exclusive_content_livestreams" | translate | uppercase }}</h2>
            </div>
            <!-- <p class="m-t-20" [innerHTML]="'newdash.exclusive_content_slug' | translate"></p> -->
            <h3 class="brandfont-bold">This video is only available to {{ env.studioOnePlusBrandName }} subscribers.</h3>
            <p class="m-t-20">
              These videos are designed to help you hone your audio skills with two new masterclasses added each month, on topics ranging from compression to sampling—and everything in between. In
              addition to masterclasses, PreSonus product specialists also host live streams exclusively for {{ env.studioOnePlusBrandName }} members twice a month. Live streams cover everything from
              mix critiques to interviews with internationally known producers and more. Join {{ env.studioOnePlusBrandName }} to binge-watch this growing collection of educational videos.
            </p>
            <button class="btn btn-primary" (click)="upgrade()">{{ "newdash.get_sphere" | translate }}</button>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="comments-container" *ngIf="!minimized">
      <div class="video-sidebar-header">
        <div class="d-flex justify-content-between m-y-5">
          <div>
            <div class="pin-container m-y-5" *ngIf="shouldShowPinOption">
              <input type="checkbox" id="pin-comments" [(ngModel)]="pinnedToBottom" (ngModelChange)="measureUI()" />
              <label for="pin-comments">Pin comments to bottom</label>
            </div>
          </div>
          <div>
            <button class="fa-button" (click)="minimized = !minimized" ngbTooltip="Minimize Video">
              <fa-icon icon="compress" class="circle-icon-btn m-r-5"></fa-icon>
              <span class="sr-only">Minimize Video</span>
            </button>
            <button id="close-modal-button" class="fa-button" (click)="close()" ngbTooltip="Close Video">
              <fa-icon icon="times" class="circle-icon-btn m-r-5"></fa-icon>
              <span class="sr-only">Close Video</span>
            </button>
          </div>
        </div>
        <myp-segmented-bar
          *ngIf="presonusVideo"
          [white]="true"
          [isUsedAsTab]="true"
          (selected)="selectedSection = $event.id"
          [options]="[
            {
              name: 'Details',
              id: 'details',
              selected: true,
            },
            {
              name: 'Comments',
              id: 'comments',
            },
            {
              name: 'My Notes',
              id: 'mynotes',
            },
          ]"
        ></myp-segmented-bar>
      </div>
      <div
        [ngStyle]="{ height: chatHeight + 'px' }"
        *ngIf="selectedSection == 'details'"
        aria-labelledby="tab-details"
        class="video-details c-white trans-bg border-radius10 h-full"
        role="tabpanel"
        id="details-tabpanel"
        tabindex="0"
      >
        <div class="p-10">
          <h1 id="modal-title" class="brandfont-bold t-20">{{ presonusVideo?.title }}</h1>

          <h2 class="t-14 bold">Description</h2>
          <div class="lighter-link" *ngIf="presonusVideo?.description" [innerHTML]="helperService.makeHtml(presonusVideo?.description) | safe: 'html'"></div>
          <hr />

          <ng-container *ngIf="lesson">
            <span *ngIf="lessonNextVideo">You're watching video #{{ lessonWatchingIndex + 1 }} from the {{ lesson.title }} lesson.</span>
            <span *ngIf="!lessonNextVideo">You're watching the final video in the {{ lesson.title }} lesson.</span>
            <br />
            <button class="small-btn force-light" (click)="goToLesson()">Go To Lesson</button>
            <br />
            <br />
            <ng-container *ngIf="lessonNextVideo">
              Next up in this lesson:
              <div class="trans-bg p-10 border-radius10">
                <myp-video-grid-item [video]="lessonNextVideo" [lessonID]="lesson ? lesson.id : null"></myp-video-grid-item>
              </div>
              <hr />
            </ng-container>
            <div *ngIf="lessonPrevVideo" class="m-t-20">
              Previous video in {{ lesson.title }}
              <br />
              <button class="brandfont-bold link" (click)="showPreviousVideo()">
                <fa-icon class="link" icon="chevron-left"></fa-icon>
                {{ lessonPrevVideo.title }}
              </button>
              <hr />
            </div>
          </ng-container>

          <ng-container *ngIf="presonusVideo?.tags && presonusVideo?.tags.length">
            <h2 class="t-14 bold">Tags</h2>
            <div *ngIf="presonusVideo?.tags" class="video-tags">
              <span class="pill sm trans force-dark" *ngFor="let tag of presonusVideo.tags">#{{ tag.title }}</span>
            </div>
            <hr />
          </ng-container>
          <ng-container *ngIf="presonusVideo?.products && presonusVideo?.products.length">
            <h2 class="t-14 bold">Related Products</h2>
            <myp-list-item *ngFor="let product of presonusVideo.products" [title]="product.title" [includeChevron]="true" [showImage]="false"></myp-list-item>
            <hr />
          </ng-container>
        </div>
      </div>
      <div *ngIf="selectedSection == 'comments'" aria-labelledby="tab-comments" tabindex="0" role="tabpanel">
        <myp-chat
          [height]="chatHeight"
          [commentsUI]="true"
          (commentFlagged)="flagComment($event)"
          (commentUnflagged)="unflagComment($event)"
          (commentLiked)="likeComment($event)"
          [enableDirectMessages]="false"
          #chatCmp
          (editMessage)="editComment($event)"
          (deleteMessage)="deleteComment($event)"
          (adminDeleteMessage)="adminDeleteComment($event)"
          (flagCleared)="clearFlag($event)"
          (messageSent)="addComment($event)"
          [messages]="communityService.comments"
          [parentThreadMap]="communityService.parentThreadMap"
          [forceDark]="true"
        ></myp-chat>
      </div>
      <div *ngIf="selectedSection == 'mynotes'" aria-labelledby="tab-mynotes" tabindex="0" role="tabpanel" class="last-focusable-element">
        <myp-video-notes [height]="chatHeight" [videoId]="presonusVideo.id" [activeTime]="activeTime" (playVideoFrom)="seekTo($event)"></myp-video-notes>
      </div>
      <!-- <div [ngStyle]="{'height': chatHeight + 'px' }" *ngIf="selectedSection == 'mynotes'" class="creatch-pad p-10 trans-bg m-2 border-radius10 h-full">
        <input type="text" class="" placeholder="Enter your notes for this video. They will be saved automatically." />
      </div>  -->
    </div>
  </div>
</div>
