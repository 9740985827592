<div class="modal-header">
  <h4 class="modal-title">Upload Failed</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <div>
    Your upload of
    <strong>{{ data.paeEntity.fileName }}</strong>
    failed. Please upload it again in the Content section of product detail.
  </div>
  <div></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
