<div class="modal-header">
  <h4 class="modal-title">Uploads</h4>
  <button ngbAutofocus class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" ngbAutofocus>
  <ng-container *ngIf="!contentServiceUploading">
    <div *ngIf="!fileCount" class="text-center">
      <fa-icon icon="check-circle" style="color: green; font-size: 65px"></fa-icon>
      <br />
      Uploads complete!
    </div>
    <div *ngFor="let item of items | keyvalue" class="d-flex align-items-center upload-row position-relative m-y-10">
      <div style="width: 50px; position: relative" class="m-r-10">
        <div style="position: absolute; display: flex; width: 50px; height: 50px; justify-content: center; align-items: center">
          <span class="bold">{{ item.value.progress }}%</span>
        </div>
        <round-progress
          [current]="item.value.progress"
          max="100"
          [color]="'#00529c'"
          [background]="'#eaeaea'"
          [radius]="25"
          [stroke]="5"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="true"
          [responsive]="true"
          [duration]="250"
          [animation]="'easeInOutQuart'"
          [animationDelay]="0"
        ></round-progress>
      </div>
      <span class="bold">
        {{ item.value.file?.filename }}
      </span>
    </div>
  </ng-container>
  <ng-container *ngIf="contentServiceUploading">
    <div *ngIf="!contentService.uploadSession.totalFilesUploading" class="text-center">
      <fa-icon icon="check-circle" style="color: green; font-size: 65px"></fa-icon>
      <br />
      Uploads complete!
    </div>
    <div *ngFor="let item of this.contentService.uploadSession.filesMap | keyvalue" class="d-flex align-items-center upload-row position-relative m-y-10">
      <div style="width: 50px; position: relative" class="m-r-10">
        <div style="position: absolute; display: flex; width: 50px; height: 50px; justify-content: center; align-items: center">
          <span class="bold">{{ item.value.progress }}%</span>
        </div>
        <round-progress
          [current]="item.value.progress"
          max="100"
          [color]="'#00529c'"
          [background]="'#eaeaea'"
          [radius]="25"
          [stroke]="5"
          [semicircle]="false"
          [rounded]="true"
          [clockwise]="true"
          [responsive]="true"
          [duration]="250"
          [animation]="'easeInOutQuart'"
          [animationDelay]="0"
        ></round-progress>
      </div>
      <span class="bold">
        {{ item.value.file?.file?.name }}
      </span>
    </div>
  </ng-container>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">Close</button>
</div>
