<div class="row">
  <div class="co-12 col-lg-6 m-b-30">
    <h2 class="section-header m-b-20">{{ keyType == "oem" ? "OEM Key Import" : "Third Party License Import" }}</h2>
    <myp-inline-loader [loading]="loading" *ngIf="loading" height="200px" spinnerName="keygenloader"></myp-inline-loader>
    <ng-container *ngIf="!loading">
      <ng-container *ngIf="!uploadingComplete">
        <div class="form-group">
          <label class="control-label" for="provider">{{ keyType == "oem" ? "Provider" : "Product" }}</label>
          <select class="form-control" *ngIf="keyType == 'oem'" id="provider" name="provider" [(ngModel)]="provider" (ngModelChange)="providerChange($event)">
            <option value="-1">Select a Provider</option>
            <option *ngFor="let id of data['String IDs']">{{ id }}</option>
          </select>
          <select class="form-control" *ngIf="keyType == 'tpl'" id="provider" name="provider" [(ngModel)]="provider" (ngModelChange)="providerChange($event)">
            <option value="-1">Select a Product...</option>
            <option *ngFor="let item of data.flatProducts" [value]="item.id">{{ item.title }}</option>
          </select>
        </div>
        <div class="form-group" *ngIf="provider != '-1'">
          <div
            ng2FileDrop
            class="content-bg content-drop-zone m-b-20"
            *ngIf="!uploader || (!uploader.queue.length && !uploadingComplete)"
            [class.hovering]="hasBaseDropZoneOver"
            (fileOver)="fileOverBase($event)"
            [uploader]="uploader"
          >
            <div>{{ (hasBaseDropZoneOver ? "profile.edit.dropit" : "profile.edit.drophere") | translate }}</div>
          </div>
          <div class="content-bg m-b-20 d-flex justify-content-between align-items-center" *ngIf="uploadingFile">
            <span class="brandfont-black">{{ uploadingFile.file.name }}</span>
            <button class="btn btn-sm btn-secondary" (click)="clearFile()">Remove</button>
          </div>
          <button class="btn btn-primary" *ngIf="uploadingFile" (click)="upload()">Upload</button>
        </div>
      </ng-container>
      <div class="text-center m-t-30" *ngIf="uploadingComplete">
        <fa-icon class="c-green" style="font-size: 50px" icon="circle-check"></fa-icon>
        <br />
        <span class="brandfont-black t-16">Uploading Complete!</span>
        <br />
        Your file has been uploaded and is being processed. The keys will be imported automatically.
        <br />
        <br />
        <span class="btn btn-sm btn-secondary" (click)="clearFile()">Done</span>
      </div>
    </ng-container>
  </div>
  <div class="col-12 col-lg-6" *ngIf="!loading">
    <h2 class="section-header">Remaining Keys</h2>
    <div class="row t-14">
      <div class="col-6 m-b-20">
        <strong>{{ keyType == "oem" ? "Provider" : "Product" }}</strong>
      </div>
      <div class="col-6 m-b-20"><strong>Remaining Keys</strong></div>

      <ng-container *ngFor="let key of data.Statistic | keyvalue">
        <div class="col-6" [class.c-red]="key.value == 0">{{ key.key }}</div>
        <div class="col-6" [class.c-red]="key.value == 0">{{ key.value }}</div>
        <div class="col-12">
          <hr class="m-y-10" />
        </div>
      </ng-container>
    </div>
  </div>
</div>
