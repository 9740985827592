import { Component, Input, OnInit, ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "myp-paginator",
  templateUrl: "paginator.component.html",
})
export class PaginatorComponent {
  @Input() limit: number;
  @Input() offset: number;
  @Input() resultsLength: number;
  @Input() totalPages?: number;
  @Input() totalResults?: number;
  @Input() async: boolean = true;

  private _loadingNext: boolean;
  @Input() set loadingNext(value: boolean) {
    this._loadingNext = value;
    this.cdr.detectChanges();
  }
  get loadingNext() {
    return this._loadingNext;
  }

  private _loadingPrevious: boolean;
  @Input() set loadingPrevious(value: boolean) {
    this._loadingPrevious = value;
    this.cdr.detectChanges();
  }
  get loadingPrevious() {
    return this._loadingPrevious;
  }

  @Input() paginate: (page: string) => void;

  constructor(private cdr: ChangeDetectorRef) {}

  onPaginate(page: string) {
    if (this.async) {
      if (page === "next") {
        this.loadingNext = true;
      } else if (page === "previous") {
        this.loadingPrevious = true;
      }
    }
    this.paginate(page);
  }
}
