import { ActivateOfflineComponent } from "./activate-offline/activate-offline.component";
import { ThemeChooserComponent } from "./theme-chooser/theme-chooser.component";
import { TransferLicenseComponent } from "./transfer-license/transfer-license.component";
import { UpgradeSoftwareComponent } from "./upgrade-software/upgrade-software.component";
import { ManageActivationsComponent } from "./manage-activations/manage-activations.component";
import { PreviousVersionsComponent } from "./previous-versions/previous-versions.component";
import { FirmwareInstructionsComponent } from "./firmware-instructions/firmware-instructions.component";
import { RegisterProductComponent } from "./register-product/register-product.component";
import { RegisterOptionCardComponent } from "./register-option-card/register-option-card.component";
import { IlokComponent } from "./ilok/ilok.component";
import { UploadWareComponent } from "./upload-ware/upload-ware.component";
import { EditAddressComponent } from "./edit-address/edit-address.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { DownloadDataComponent } from "./download-data/download-data.component";
import { DeleteDataComponent } from "./delete-data/delete-data.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { ForgotpasswordComponent } from "./forgotpassword/forgotpassword.component";
import { MagicbundleRedeemSuccessComponent } from "./magicbundle-redeem-success/magicbundle-redeem-success.component";
import { TermsofuseComponent } from "./termsofuse/termsofuse.component";
import { RecentOrderComponent } from "./recent-order/recent-order.component";
import { SwitchLanguageComponent } from "./switch-language/switch-language.component";
import { ProductDataErrorComponent } from "./product-data-error/product-data-error.component";
import { CreateTicketComponent } from "./create-ticket/create-ticket.component";
import { TicketAddCommentComponent } from "./ticket-add-comment/ticket-add-comment.component";
import { AddReviewComponent } from "./add-review/add-review.component";
import { SubmitFeedbackComponent } from "./submit-feedback/submit-feedback.component";
import { DenyWareComponent } from "./deny-ware/deny-ware.component";
import { ExchangeRequireStudiooneComponent } from "./exchange-require-studioone/exchange-require-studioone.component";
import { AddWorkspaceComponent } from "./add-workspace/add-workspace.component";
import { WorkspaceUploadComponent } from "./workspace-upload/workspace-upload.component";
import { ManageCollaboratorsComponent } from "./manage-collaborators/manage-collaborators.component";
import { AddEditFeaturedCreatorComponent } from "./add-edit-featured-creator/add-edit-featured-creator.component";
import { AddEditAnnouncementComponent } from "./add-edit-announcement/add-edit-announcement.component";
import { AddEditProductSplashComponent } from "./add-edit-product-splash/add-edit-product-splash.component";
import { ActionComponent } from "./action/action.component";
import { UpdatePaymentSourceComponent } from "./update-payment-source/update-payment-source.component";
import { CancelSubscriptionComponent } from "./cancel-subscription/cancel-subscription.component";
import { CancelSubscriptionStripeComponent } from "./cancel-subscription-stripe/cancel-subscription-stripe.component";
import { SubscribeComponent } from "./subscribe/subscribe.component";
import { CreateFolderComponent } from "./create-folder/create-folder.component";
import { MoveFileComponent } from "./move-file/move-file.component";
import { EditCommentComponent } from "./edit-comment/edit-comment.component";
import { RedeemProductsComponent } from "./redeem-products/redeem-products.component";
import { BetaEnrollComponent } from "./beta-enroll/beta-enroll.component";
import { AddEditBetaProgramComponent } from "./add-edit-beta-program/add-edit-beta-program.component";
import { SimpleSignupComponent } from "./simple-signup/simple-signup.component";
import { CreatePublicLinkComponent } from "./create-public-link/create-public-link.component";
import { FeatureUpdatesComponent } from "./feature-updates/feature-updates.component";
import { AddSatelliteConnectionComponent } from "./add-satellite-connection/add-satellite-connection.component";
import { RedeemHardwareOfferComponent } from "./redeem-hardware-offer/redeem-hardware-offer.component";
import { AddEditSkillComponent } from "./add-edit-skill/add-edit-skill.component";
import { AdminAddSkillComponent } from "./admin-add-skill/admin-add-skill.component";
import { CreateNewThreadComponent } from "./create-new-thread/create-new-thread.component";
import { SelectListItemComponent } from "./select-list-item/select-list-item.component";
import { SelectCountryComponent } from "./select-country/select-country.component";
import { UploadDetailsComponent } from "./upload-details/upload-details.component";
import { AddEditShowcaseComponent } from "./add-edit-showcase/add-edit-showcase.component";
import { RegisterSuccessSplashComponent } from "./register-success-splash/register-success-splash.component";
import { AddEditTopicComponent } from "./add-edit-topic/add-edit-topic.component";
import { EditVideoComponent } from "./edit-video/edit-video.component";
import { AddEditLearnGroupComponent } from "./add-edit-learn-group/add-edit-learn-group.component";
import { ChooseLessonComponent } from "./choose-lesson/choose-lesson.component";
import { UpdatePaymentSourceStripeComponent } from "./update-payment-source-stripe/update-payment-source-stripe.component";
import { UpdatePaymentSourcePaypalStripeComponent } from "./update-payment-source-paypal-stripe/update-payment-source-paypal-stripe.component";
import { StudiooneGracePeriodComponent } from "./studioone-grace-period/studioone-grace-period.component";
import { AddConnectionComponent } from "./add-connection/add-connection.component";
import { HtmlOverlayComponent } from "./html-overlay/html-overlay.component";
import { SearchContentComponent } from "./search-content/search-content.component";
import { ReviewConnectionComponent } from "./review-connection/review-connection.component";
import { VerifyEmailComponent } from "./verify-email/verify-email.component";
import { ApplicationUpdateComponent } from "./application-update/application-update.component";
import { UpgradeSoftwareStripeComponent } from "./upgrade-software-stripe/upgrade-software-stripe.component";
import { GenericProductPurchase } from "./generic-product-purchase/generic-product-purchase.component";
import { SphereSubscribeStripeComponent } from "./sphere-subscribe-stripe/sphere-subscribe-stripe.component";
import { TransferProductComponent } from "./transfer-product/transfer-product.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { AddEditPreviousVersionComponent } from "./add-edit-previous-version/add-edit-previous-version.component";
import { AddEditProductContentComponent } from "./add-edit-product-content/add-edit-product-content.component";
import { MissedPaymentModalComponent } from "./missed-payment-modal/missed-payment-modal.component";
import { ConfirmPlanSwitchComponent } from "./confirm-plan-switch/confirm-plan-switch.component";
import { AdminAddNewProductComponent } from "./admin-add-new-product/admin-add-new-product.component";
import { BetaDetailComponent } from "./beta-detail/beta-detail.component";
import { CancelSubscriptionRechargeComponent } from "./cancel-subscription-recharge/cancel-subscription-recharge.component";
import { RechargeOptionsComponent } from "./recharge-options/recharge-options.component";
import { ConfirmBetaAnnouncementEmail } from "./confirm-beta-announcement-email/confirm-beta-announcement-email.component";
import { CreateJiraTicketComponent } from "./create-jira-ticket/create-jira-ticket.component";
import { GenericAlertComponent } from "./generic-alert/generic-alert.component";
import { AdminUploadFailedComponent } from "./admin-upload-failed/admin-upload-failed.component";

export const MODALS_COMPONENTS = [
  ActivateOfflineComponent,
  ThemeChooserComponent,
  TransferLicenseComponent,
  UpgradeSoftwareComponent,
  UpgradeSoftwareStripeComponent,
  GenericProductPurchase,
  ManageActivationsComponent,
  PreviousVersionsComponent,
  FirmwareInstructionsComponent,
  RegisterProductComponent,
  RegisterOptionCardComponent,
  IlokComponent,
  UploadWareComponent,
  EditAddressComponent,
  UploadFileComponent,
  DownloadDataComponent,
  DeleteDataComponent,
  ChangePasswordComponent,
  ForgotpasswordComponent,
  MagicbundleRedeemSuccessComponent,
  TermsofuseComponent,
  RecentOrderComponent,
  SwitchLanguageComponent,
  ProductDataErrorComponent,
  CreateTicketComponent,
  TicketAddCommentComponent,
  AddReviewComponent,
  SubmitFeedbackComponent,
  DenyWareComponent,
  ExchangeRequireStudiooneComponent,
  AddWorkspaceComponent,
  WorkspaceUploadComponent,
  ManageCollaboratorsComponent,
  AddEditFeaturedCreatorComponent,
  AddEditAnnouncementComponent,
  ConfirmBetaAnnouncementEmail,
  AddEditProductSplashComponent,
  ActionComponent,
  UpdatePaymentSourceComponent,
  CancelSubscriptionComponent,
  CancelSubscriptionStripeComponent,
  CancelSubscriptionRechargeComponent,
  SubscribeComponent,
  CreateFolderComponent,
  MoveFileComponent,
  EditCommentComponent,
  RedeemProductsComponent,
  BetaEnrollComponent,
  AddEditBetaProgramComponent,
  CreatePublicLinkComponent,
  SimpleSignupComponent,
  FeatureUpdatesComponent,
  AddSatelliteConnectionComponent,
  RedeemHardwareOfferComponent,
  AddEditSkillComponent,
  AddEditTopicComponent,
  AdminAddSkillComponent,
  CreateNewThreadComponent,
  SelectListItemComponent,
  SelectCountryComponent,
  UploadDetailsComponent,
  AddEditShowcaseComponent,
  RegisterSuccessSplashComponent,
  EditVideoComponent,
  AddEditLearnGroupComponent,
  ChooseLessonComponent,
  UpdatePaymentSourceStripeComponent,
  UpdatePaymentSourcePaypalStripeComponent,
  StudiooneGracePeriodComponent,
  AddConnectionComponent,
  HtmlOverlayComponent,
  GenericAlertComponent,
  SearchContentComponent,
  ReviewConnectionComponent,
  VerifyEmailComponent,
  ApplicationUpdateComponent,
  SphereSubscribeStripeComponent,
  RechargeOptionsComponent,
  TransferProductComponent,
  DatePickerComponent,
  AddEditPreviousVersionComponent,
  AddEditProductContentComponent,
  MissedPaymentModalComponent,
  ConfirmPlanSwitchComponent,
  AdminAddNewProductComponent,
  BetaDetailComponent,
  CreateJiraTicketComponent,
  AdminUploadFailedComponent,
];

export * from "./theme-chooser/theme-chooser.component";
export * from "./activate-offline/activate-offline.component";
export * from "./transfer-license/transfer-license.component";
export * from "./upgrade-software/upgrade-software.component";
export * from "./upgrade-software-stripe/upgrade-software-stripe.component";
export * from "./generic-product-purchase/generic-product-purchase.component";
export * from "./manage-activations/manage-activations.component";
export * from "./previous-versions/previous-versions.component";
export * from "./firmware-instructions/firmware-instructions.component";
export * from "./register-product/register-product.component";
export * from "./register-option-card/register-option-card.component";
export * from "./register-success-splash/register-success-splash.component";
export * from "./ilok/ilok.component";
export * from "./upload-ware/upload-ware.component";
export * from "./edit-address/edit-address.component";
export * from "./upload-file/upload-file.component";
export * from "./download-data/download-data.component";
export * from "./delete-data/delete-data.component";
export * from "./change-password/change-password.component";
export * from "./forgotpassword/forgotpassword.component";
export * from "./magicbundle-redeem-success/magicbundle-redeem-success.component";
export * from "./termsofuse/termsofuse.component";
export * from "./recent-order/recent-order.component";
export * from "./switch-language/switch-language.component";
export * from "./product-data-error/product-data-error.component";
export * from "./create-ticket/create-ticket.component";
export * from "./ticket-add-comment/ticket-add-comment.component";
export * from "./add-review/add-review.component";
export * from "./submit-feedback/submit-feedback.component";
export * from "./deny-ware/deny-ware.component";
export * from "./exchange-require-studioone/exchange-require-studioone.component";
export * from "./add-workspace/add-workspace.component";
export * from "./workspace-upload/workspace-upload.component";
export * from "./manage-collaborators/manage-collaborators.component";
export * from "./add-edit-featured-creator/add-edit-featured-creator.component";
export * from "./add-edit-announcement/add-edit-announcement.component";
export * from "./confirm-beta-announcement-email/confirm-beta-announcement-email.component";
export * from "./add-edit-topic/add-edit-topic.component";
export * from "./add-edit-product-splash/add-edit-product-splash.component";
export * from "./action/action.component";
export * from "./update-payment-source/update-payment-source.component";
export * from "./cancel-subscription/cancel-subscription.component";
export * from "./cancel-subscription-stripe/cancel-subscription-stripe.component";
export * from "./cancel-subscription-recharge/cancel-subscription-recharge.component";
export * from "./subscribe/subscribe.component";
export * from "./create-folder/create-folder.component";
export * from "./move-file/move-file.component";
export * from "./edit-comment/edit-comment.component";
export * from "./redeem-products/redeem-products.component";
export * from "./beta-enroll/beta-enroll.component";
export * from "./add-edit-beta-program/add-edit-beta-program.component";
export * from "./create-public-link/create-public-link.component";
export * from "./simple-signup/simple-signup.component";
export * from "./feature-updates/feature-updates.component";
export * from "./add-satellite-connection/add-satellite-connection.component";
export * from "./redeem-hardware-offer/redeem-hardware-offer.component";
export * from "./add-edit-skill/add-edit-skill.component";
export * from "./admin-add-skill/admin-add-skill.component";
export * from "./create-new-thread/create-new-thread.component";
export * from "./select-list-item/select-list-item.component";
export * from "./upload-details/upload-details.component";
export * from "./add-edit-showcase/add-edit-showcase.component";
export * from "./edit-video/edit-video.component";
export * from "./add-edit-learn-group/add-edit-learn-group.component";
export * from "./choose-lesson/choose-lesson.component";
export * from "./update-payment-source-stripe/update-payment-source-stripe.component";
export * from "./update-payment-source-paypal-stripe/update-payment-source-paypal-stripe.component";
export * from "./studioone-grace-period/studioone-grace-period.component";
export * from "./html-overlay/html-overlay.component";
export * from "./generic-alert/generic-alert.component";
export * from "./search-content/search-content.component";
export * from "./review-connection/review-connection.component";
export * from "./verify-email/verify-email.component";
export * from "./select-country/select-country.component";
export * from "./application-update/application-update.component";
export * from "./sphere-subscribe-stripe/sphere-subscribe-stripe.component";
export * from "./recharge-options/recharge-options.component";
export * from "./transfer-product/transfer-product.component";
export * from "./date-picker/date-picker.component";
export * from "./add-edit-previous-version/add-edit-previous-version.component";
export * from "./create-jira-ticket/create-jira-ticket.component";
export * from "./add-edit-product-content/add-edit-product-content.component";
export * from "./missed-payment-modal/missed-payment-modal.component";
export * from "./confirm-plan-switch/confirm-plan-switch.component";
export * from "./admin-add-new-product/admin-add-new-product.component";
export * from "./beta-detail/beta-detail.component";
export * from "./admin-upload-failed/admin-upload-failed.component";
