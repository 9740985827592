import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { BaseComponent, UserService, StorageService, ExchangeService, HelperService, environment, WorkspaceService, WindowService, ContentService } from "@mypxplat/xplat/core";
import { AppService, AvoService } from "@mypxplat/xplat/web/core";
import { FileUploader } from "ng2-file-upload";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
@Component({
  selector: "myp-workspace-upload",
  templateUrl: "workspace-upload.component.html",
})
export class WorkspaceUploadComponent extends BaseComponent implements OnInit {
  public loading: boolean = true;
  public env = environment;

  @Output() fileCreated: EventEmitter<any> = new EventEmitter();
  @Output() fileUploadFinished: EventEmitter<any> = new EventEmitter();
  @Output() uploadProgressUpdated: EventEmitter<any> = new EventEmitter();
  @Output() uploadProgressChanged: EventEmitter<any> = new EventEmitter();
  @Output() invalidFileTypeChosen: EventEmitter<any> = new EventEmitter();
  @Input() isPostAttachment: boolean;
  @Input() isThreadAttachment: boolean;
  @Input() isShowcase: boolean;
  @Input() threadID: boolean;
  @Input() workspace_id: any;
  @Input() myWorkspace: boolean;
  @Input() file_path: boolean;
  @Input() folder_id: boolean;
  @Input() mainMix: boolean;
  @Input() description: boolean;
  @Input() coverPhoto: boolean;
  @Input() droppedFile: any;
  @Input() allowedTypes: Array<string>;
  public unsupportedFileTypeOrSize: boolean = false;
  public storageData: any;
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public uploadingFiles: any = [];
  public fileResponse: any;
  public newHeader: any;
  public fileToUpload: any = {};
  public filesToUpload: any = {};
  public filesCount: number = 0;
  public fileDescription: string;
  public savedFile: any;
  public progress: number;
  private _updateProgressInterval: any;
  public error: any;
  public audioTypes: any = {
    "audio/wav": true,
    "audio/mp3": true,
    "audio/mpeg": true,
  };
  public imageFiles: any = {
    "image/jpg": true,
    "image/gif": true,
    "image/png": true,
    "image/jpeg": true,
  };
  public disallowedExts: any = {
    js: true,
    exe: true,
    sh: true,
    ts: true,
    html: true,
    deb: true,
    rpm: true,
    php: true,
    py: true,
    java: true,
    c: true,
    cpp: true,
    app: true,
    dll: true,
    dylib: true,
    so: true,
    bundle: true,
  };
  public exceeded: boolean;

  constructor(
    public storageService: StorageService,
    public userService: UserService,
    public activeModal: NgbActiveModal,
    public router: Router,
    public helperService: HelperService,
    public workspaceService: WorkspaceService,
    private _win: WindowService,
    public appService: AppService,
    public avoService: AvoService,
    public contentService: ContentService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.userService.getStorageDetails().subscribe((result) => {
      this.storageData = result;
      if (this.myWorkspace && this.userService.subDetails && this.userService.subDetails.storage_details.plan_total < this.storageData.used) {
        this.exceeded = true;
      }
      if (!this.isPostAttachment && !this.isThreadAttachment) {
        this.loading = false;
      } else {
        if (!this.droppedFile) {
          this.loading = false;
        }
      }
      this.init();
    });
  }

  public justAddedFile: any;
  init() {
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "PUT",
      queueLimit: this.isPostAttachment ? 5 : 1,
      autoUpload: false,
      disableMultipart: true,
      allowedMimeType: this.allowedTypes ? this.allowedTypes : null,
      url: "",
    });
    this.uploader.onAfterAddingFile = (f) => {
      let delay = 300 * this.filesCount;
      this.filesCount++;
      f.file.name = f.file.name.replace(/:/g, "").replace(/\s+/g, " "); // this plugin replaces slashes with colons.
      this._win.setTimeout(() => {
        let parts = f.file.name.split(".");
        if (f.file.type || parts.length > 1) {
          if (this.mainMix && !this.audioTypes[f.file.type]) {
            alert("The file you selected is not an audio file.");
            this.mainMix = false;
          } else if (this.coverPhoto && !this.imageFiles[f.file.type]) {
            alert("The file you selected is not an image.");
            this.activeModal.close();
          }
          let ext = parts[parts.length - 1];
          if (this.disallowedExts[ext]) {
            alert("Sorry, ." + ext + " files are not allowed.");
            this.activeModal.close();
            return false;
          }

          let toUpload: any = {
            filename: f.file.name,
            user_id: this.user.id,
            workspace_id: this.workspace_id,
            filesize: f.file.size, //bytes
            extension: ext,
            mimetype: f.file.type,
            mainmix: this.mainMix,
            coverphoto: this.coverPhoto,
            description: this.description ? this.description : "",
          };
          if (this.isPostAttachment) {
            toUpload.type = "post_attachment";
          } else if (this.isThreadAttachment) {
            toUpload.type = "thread_attachment";
          } else if (this.isShowcase) {
            toUpload.type = "showcase";
          }
          if (this.threadID) toUpload.thread_id = this.threadID;
          if (this.folder_id) toUpload.folder_id = this.folder_id;

          f.withCredentials = false;
          let uploadHeaders = [];
          uploadHeaders.push({ name: "Content-Type", value: toUpload.mimetype });
          uploadHeaders.push({ name: "Content-Disposition", value: "attachment; filename=" + toUpload.filename }); // this is breaking upload for some filenames that are non ASCII standard
          this.fileToUpload = toUpload;
          if (!this.filesToUpload) this.filesToUpload = {};
          this.filesToUpload[toUpload.filename] = {
            file: toUpload,
            fileItem: f,
            headers: uploadHeaders,
          };
          if (this.isPostAttachment || this.isThreadAttachment) this.getUploadUrl(toUpload);
        } else {
          alert("It looks like you're trying to upload a folder. Please create and upload .zip file of the folder.");
          this.activeModal.close();
        }
      }, delay);
    };

    this.uploader.onWhenAddingFileFailed = (resp) => {
      this.invalidFileTypeChosen.next(resp);
      if (this.isThreadAttachment) this.activeModal.close();
      this.unsupportedFileTypeOrSize = true;
    };
    this.uploader.onErrorItem = (error: any) => {
      this.error = "There was an error uploading your file, please try again later.";
      this._win.clearInterval(this._updateProgressInterval);
    };
    this.uploader.onProgressItem = (item: any, progress: any) => {
      let uploadingFile = this.filesToUpload[item.file.name].file;

      this.progress = progress;
      this.workspaceService.fileMap[uploadingFile.id].progress = progress;
      if (!this.workspaceService.uploadingFilesMap[uploadingFile.id]) this.workspaceService.uploadingFilesMap[uploadingFile.id] = { file: uploadingFile };
      if (!this.workspaceService.uploadingFilesSessionMap[uploadingFile.id]) this.workspaceService.uploadingFilesSessionMap[uploadingFile.id] = { file: uploadingFile };
      this.workspaceService.uploadingFilesMap[uploadingFile.id].progress = progress;
      this.workspaceService.uploadingFilesSessionMap[uploadingFile.id].progress = progress;
      this.workspaceService.fileUploadProgressUpdate$.next(true);
      this.workspaceService.uploadingFile$.next({ file: uploadingFile, progress: progress });
      this.uploadProgressChanged.emit({ file: uploadingFile, progress: progress });
      if (progress == 100) {
        this.uploadingComplete = true;
        this.workspaceService.uploadingFile$.next(undefined);
        delete this.workspaceService.uploadingFilesMap[uploadingFile.id];
        this.workspaceService.fileUploadProgressUpdate$.next(true);
        this.uploadProgressChanged.emit({ file: uploadingFile, progress: 100 });
        this._win.clearInterval(this._updateProgressInterval);
        this.workspaceService.updateUploadProgress(uploadingFile.id, 100).subscribe((result) => {
          this.uploadProgressUpdated.emit({ file: uploadingFile, progress: 100 });
        });
      }
    };
    this.uploader.onSuccessItem = (item, response) => {
      this._win.setTimeout(() => {
        this.fileUploadFinished.next(this.filesToUpload[item.file.name].file);
        let uploadingFile = this.filesToUpload[item.file.name].file;
        this.workspaceService.updateUploadProgress(uploadingFile.id, 100).subscribe((result) => {
          this.uploadProgressUpdated.emit({ file: uploadingFile, progress: 100 });
        });
        delete this.workspaceService.uploadingFilesMap[this.filesToUpload[item.file.name].file.id];
        this.workspaceService.fileUploadProgressUpdate$.next(true);
        if (Object.keys(this.workspaceService.uploadingFilesMap).length == 0) {
          this.workspaceService.uploadingFilesSessionMap = {};
          this.workspaceService.fileUploadSessionCompleted$.next(true);
        }
      }, 2000);
    };
    if (this.droppedFile) this.uploader.addToQueue(this.droppedFile);
  }

  getUploadUrl(fileToUpload?) {
    const go = (fileToUpload) => {
      if (this.mainMix) fileToUpload.mainmix = true;
      this.loading = true;
      this.contentService.getPresignedUploadURL(fileToUpload).subscribe((result: any) => {
        this._win.setTimeout(() => {
          this.activeModal.close();
        }, 200);
        this._updateProgressInterval = this._win.setInterval(() => {
          if (this.progress) {
            this.workspaceService.updateUploadProgress(this.savedFile.id, this.progress).subscribe((result) => {
              this.uploadProgressUpdated.emit({ file: this.savedFile, progress: this.progress });
            });
          }
        }, 2000);
        if (!this.isPostAttachment && !this.isThreadAttachment) this.loading = false;
        if (result && result.data && result.data.upload_url) {
          this.fileCreated.next(result.data.shared_data);
          let key = result.data.shared_data.filename;
          let noSpecialCharsFilename = fileToUpload.filename.replace(/[#+%><*?}{|$=`!:]/g, "").replace(/\s+/g, " ");
          let noSpecialCharsFilenameLower = fileToUpload.filename
            .replace(/[#+%><*?}{|$=`!:]/g, "")
            .toLowerCase()
            .replace(/\s+/g, " ");

          if (result.already_exist && result.already_exist.filename) {
            const normalizedAlreadyExistFilename = result.already_exist.filename.replace(/\s+/g, " ");
            if (normalizedAlreadyExistFilename == noSpecialCharsFilename || normalizedAlreadyExistFilename == noSpecialCharsFilenameLower) {
              key = fileToUpload.filename;
            }
          }
          this.filesToUpload[key].file = result.data.shared_data;
          this.savedFile = result.data.shared_data;
          this.workspaceService.fileMap[this.savedFile.id] = this.savedFile;
          const url = result.data.upload_url;
          this.uploader.queue.forEach((file) => {
            if (file.file.name == fileToUpload.filename) {
              file.headers = this.filesToUpload[key].headers;
              file.url = url;
              this.uploader.uploadItem(file);
            }
          });
          if (!this.workspaceService.uploadingFilesMap[result.data.shared_data.id])
            this.workspaceService.uploadingFilesMap[result.data.shared_data.id] = { file: result.data.shared_data, progress: 0 };
          if (!this.workspaceService.uploadingFilesSessionMap[result.data.shared_data.id])
            this.workspaceService.uploadingFilesSessionMap[result.data.shared_data.id] = { file: result.data.shared_data, progress: 0 };
        }
      });
      this.avoService.trackEvent().workspaceFileUploaded({
        siteId: this.userService.user?.active_subscription ? "studio_one_plus" : "mypresonus",
        workspaceId: parseInt(this.workspace_id),
      });
    };
    if (!fileToUpload) {
      for (var i in this.filesToUpload) {
        let fileToUpload = this.filesToUpload[i].file;
        go(fileToUpload);
      }
    } else {
      go(fileToUpload);
    }
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }

  ngOnDestroy(): void {}
}
