import { Component } from "@angular/core";

import { BaseComponent, ContentService, UserService, WorkspaceService } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { interval } from "rxjs";
import { takeUntil, throttle } from "rxjs/operators";

@Component({
  selector: "myp-upload-details",
  templateUrl: "upload-details.component.html",
})
export class UploadDetailsComponent extends BaseComponent {
  public uploadingFiles = [];
  public fileCount = 0;
  public items: any;
  public contentServiceUploading: boolean;
  constructor(
    userService: UserService,
    public activeModal: NgbActiveModal,
    public workspaceService: WorkspaceService,
    public contentService: ContentService
  ) {
    super(userService);
  }

  ngOnInit() {
    if (Object.keys(this.workspaceService.uploadingFilesMap).length) {
    } else if (this.contentService.uploadSession.totalFilesUploading) {
      this.contentServiceUploading = true;
    }

    this.workspaceService.fileUploadProgressUpdate$
      .pipe(
        takeUntil(this.destroy$),
        throttle(
          () => {
            return interval(1000);
          },
          { leading: true, trailing: true }
        )
      )
      .subscribe((result) => {
        this.fileCount = Object.keys(this.workspaceService.uploadingFilesMap).length;
        if (this.fileCount) this.items = this.workspaceService.uploadingFilesMap;
      });
  }
}
