<div class="modal-header">
  <h1 class="modal-title">Add Product</h1>
  <button class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <myp-inline-loader [loading]="loading" height="200px" spinnerName="addEditProductLoader"></myp-inline-loader>

  <div class="row" *ngIf="!productSuccessArgs">
    <div class="col-12 m-b-10" *ngIf="data.categoryId == 'extensions'">
      <ng-container *ngIf="!loading">
        <div
          ng2FileDrop
          class="content-bg content-drop-zone m-b-20"
          *ngIf="!productFileParser || !productFileParser.queue.length"
          [class.hovering]="hoveringFileDrops.product"
          (fileOver)="fileOverBase($event, 'product')"
          [uploader]="productFileParser"
        >
          <div>Drop your product info file here.</div>
        </div>
        <div class="content-bg m-b-20" *ngIf="productFileData">
          <div class="brandfont-black m-b-10">{{ selectedProductFile.file.name }}</div>
          <div *ngFor="let item of productFileData | keyvalue" class="overflow-hidden text-nowrap t-12">
            <strong>{{ item.key }}:</strong>
            {{ item.value }}
          </div>
          <div class="m-b-10 t-12 text-muted">All this data will be editable once this product is created.</div>
          <button class="btn btn-sm btn-secondary" (click)="clearProductFile()">Remove</button>
        </div>
        <div
          ng2FileDrop
          class="content-bg content-drop-zone m-b-20"
          *ngIf="!imageUploader || !imageUploader.queue.length"
          [class.hovering]="hoveringFileDrops.image"
          (fileOver)="fileOverBase($event, 'image')"
          [uploader]="imageUploader"
        >
          <div>Drop your image file here.</div>
        </div>
        <div class="content-bg m-b-20 d-flex align-items-center" *ngIf="selectedImageFile">
          <img [src]="displayImage" style="width: 40px; margin-right: 10px" />
          <div class="d-flex justify-content-between align-items-center w-full">
            <span class="brandfont-black">{{ selectedImageFile.file.name }}</span>
            <button class="btn btn-sm btn-secondary" (click)="removeImage()">Remove</button>
          </div>
        </div>
        <div
          ng2FileDrop
          class="content-bg content-drop-zone m-b-20"
          *ngIf="!contentPackageUploader || !contentPackageUploader.queue.length"
          [class.hovering]="hoveringFileDrops.contentPackage"
          (fileOver)="fileOverBase($event, 'contentPackage')"
          [uploader]="contentPackageUploader"
        >
          <div>Drop your extension file here.</div>
        </div>
        <div class="content-bg m-b-20 d-flex justify-content-between align-items-center" *ngIf="selectedContentPackageFile">
          <span class="brandfont-black">{{ selectedContentPackageFile.file.name }}</span>
          <button class="btn btn-sm btn-secondary" (click)="removeContentPackage()">Remove</button>
        </div>
        <div class="form-group" *ngIf="selectedImageFile || selectedContentPackageFile">
          <div class="d-flex justify-content-between">
            <label class="control-label" for="selectedPath">Content Folder*</label>
            <span class="t-12 link cursor-pointer" (click)="chooseExistingFolder = !chooseExistingFolder">{{ chooseExistingFolder ? "Enter New Path" : "Choose Existing" }}</span>
          </div>

          <ng-container *ngIf="chooseExistingFolder">
            <myp-autocomplete-dropdown [allowNewEntry]="true" idName="hardware" [items]="existingFolders" (selected)="selectExistingFolder($event)"></myp-autocomplete-dropdown>
          </ng-container>
          <ng-container *ngIf="!chooseExistingFolder">
            <input ngbAutofocus type="text" name="selectedPath" id="selectedPath" [(ngModel)]="selectedPath" class="form-control" />
          </ng-container>
        </div>
      </ng-container>

      <!-- <div class="drop-zone" *ngIf="uploadingFile">
        <strong>Queued to upload: {{ uploadingFile.file.name }}</strong>
        <br />
        <br />
        <button class="btn btn-primary" (click)="clearFile()">Remove</button>
      </div>
      <div class="drop-zone" *ngIf="data.id && !uploadingFile && !loading && !replacingFile">
        <strong>File storage location: {{ data.folder + "/" + data.fileName }}</strong>
        <br />
        <br />
        <button class="btn btn-primary" (click)="replacingFile = true">Replace File</button>
      </div> -->
    </div>
    <ng-container *ngIf="!loading">
      <div class="form-group col-12">
        <label class="control-label" for="fileName">Title*</label>
        <input ngbAutofocus type="text" name="title" id="title" [(ngModel)]="data.title" class="form-control" />
      </div>
      <div class="form-group col-md-6">
        <label class="control-label" for="downloadSection">Type</label>
        <select class="form-control" name="downloadSection" [(ngModel)]="data.categoryId">
          <option value="software">Software</option>
          <option value="hardware">Hardware</option>
          <option value="extensions">Extension</option>
        </select>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="control-label" for="stringId">String ID*</label>
        <input type="text" name="stringId" id="stringId" [(ngModel)]="data.stringId" class="form-control" />
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="control-label" for="version">Version*</label>
        <input type="text" name="version" id="version" [(ngModel)]="data.version" class="form-control" />
      </div>
      <div class="form-group col-md-6" *ngIf="keySets?.length && data.categoryId == 'software'">
        <label class="control-label" for="keySet">Keyset*</label>
        <select class="form-control" name="keySet" [(ngModel)]="data.keySet">
          <option *ngFor="let item of keySets" [value]="item.id">{{ item.key }}</option>
        </select>
      </div>
      <div class="form-group col-12">
        <label class="control-label" for="description">Description</label>
        <textarea name="description" id="description" rows="4" [(ngModel)]="data.description" class="form-control">{{ data.description }}</textarea>
      </div>
    </ng-container>
  </div>
  <div *ngIf="productSuccessArgs">
    Your product was created successfully!
    <div class="m-y-10" *ngIf="contentService.uploadSession.totalFilesUploading">
      Your files will continue to upload in the background. You can close this modal, but please do not close or refresh your browser window.
    </div>
    <button class="btn btn-primary" (click)="activeModal.close()">View Product</button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="!productSuccessArgs" [disabled]="loading" class="btn btn-primary" (click)="submit()">
    <span>Submit</span>
  </button>
  <button type="button" class="btn btn-blank" (click)="activeModal.close('Close click')">{{ "general.close" | translate }}</button>
</div>
