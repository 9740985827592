import { Component } from "@angular/core";
import { StorageService, UserService, ProductService } from "@mypxplat/xplat/core";
import { UpgradeSoftwareBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-upgrade-software",
  templateUrl: "upgrade-software.component.html",
})
export class UpgradeSoftwareComponent extends UpgradeSoftwareBaseComponent {
  private shortMask: any = [/\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/, "-", /\w/, /\w/, /\w/, /\w/];
  private longMask: any = [
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
    "-",
    /\w/,
    /\w/,
    /\w/,
    /\w/,
  ];
  public mask = this.longMask;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public productService: ProductService,
    public activeModal: NgbActiveModal,
    public translate: TranslateService,
    private _router: Router,
    public appService: AppService
  ) {
    super(storageService, userService, productService, translate);
  }

  keyPressed(args) {
    let tester = /\w/;
    // dont do anything if its a single key and its not alphanumeric.
    if (args.key.length == 1 && !tester.test(args.key)) {
      return false;
    } else if (this.productKey) {
      let rawKey = this.productKey.replace(/_|-/g, "");
      if (args.key == "Backspace" && rawKey.length == 21) {
        this.mask = this.shortMask;
      } else if (rawKey.length > 19) {
        this.mask = this.longMask;
      } else {
        this.mask = this.shortMask;
      }
    }
  }

  performUpgrade() {
    let rawKey = this.productKey.replace(/_|-/g, "");
    if (this.productKey.includes("-____-____")) {
      this.productKey = this.productKey.slice(0, -10);
    }
    if (rawKey.length == 20 || rawKey.length == 28) {
      this.loading = true;
      let args = {
        oldProductKey: this.upgradingProduct.license.productKeyString,
        productKey: this.productKey,
      };
      this.productService.performUpgrade(args).subscribe({
        next: (result) => {
          this.productService.getProducts(true).subscribe((newProds) => {
            this.loading = false;
            this.activeModal.close();
            // find the new product in the list of products using the licenseId
            let upgradedTo = newProds.products.filter((prod) => prod.assetIds.includes(result.licenseId.toString()));
            if (upgradedTo.length) {
              this._router.navigate(["products/detail/", upgradedTo[0].id]);
            }
          });
        },
        error: (error) => {
          this.loading = false;
          this.appService.alertError(error);
          this.productKey = "";
        },
      });
    } else {
      alert("Please make sure the key you are entering is exactly 20 or 28 characters.");
    }
  }

  goToCheckout() {
    this.appService.goToCheckout("/checkout?checkoutProductType=Upgrade");
  }
}
