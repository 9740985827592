import { Component, Input, Output, EventEmitter } from "@angular/core";
import { StorageService, UserService, ProductService, WindowService, ProductLicenseItem, BaseComponent } from "@mypxplat/xplat/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AppService } from "@mypxplat/xplat/web/core";

@Component({
  selector: "myp-admin-upload-failed",
  templateUrl: "admin-upload-failed.component.html",
})
export class AdminUploadFailedComponent extends BaseComponent {
  @Input() data: any;

  constructor(
    storageService: StorageService,
    userService: UserService,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
    public win: WindowService,
    public appService: AppService
  ) {
    super(userService);
  }

  ngOnInit() {}
}
