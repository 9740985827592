<div *ngIf="show" class="audio-player" role="region" aria-label="Audio Player">
  <div *ngIf="playlistOpen" class="audio-player__mask" (click)="togglePlaylist()"></div>
  <div *ngIf="showErrorMessage" class="audio-player__error">
    <p>Sorry, there was a problem playing this file.</p>
  </div>

  <div class="audio-player__contents">
    <button class="audio-player__close audio-player__close--desktop" (click)="close()" aria-label="Close">
      <fa-icon icon="close"></fa-icon>
    </button>

    <div class="audio-player__controls">
      <button *ngIf="playlist.length > 0" (click)="changeTrack('prev')" aria-label="Previous audio">
        <fa-icon icon="chevron-left"></fa-icon>
      </button>
      <button (click)="togglePlayback()" [attr.aria-label]="isPlaying ? 'pause' : 'play'">
        <fa-icon [icon]="isPlaying ? 'pause' : 'play'"></fa-icon>
      </button>
      <button *ngIf="playlist.length > 0" (click)="changeTrack('next')" aria-label="Previous audio">
        <fa-icon icon="chevron-right"></fa-icon>
      </button>
    </div>

    <div class="audio-player__timeline">
      <div class="audio-player__timestring">{{ playbackPosition }}</div>
      <progress aria-labelledby="song_progress" class="audio-player__progress" [value]="progressValue" max="1" (click)="seek($event)"></progress>
      <div class="audio-player__timestring">{{ playbackDuration }}</div>
    </div>

    <div class="audio-player__file">
      <p id="song_progress" class="audio-player__filename">{{ fileName }}</p>
      <div class="audio-player__file-controls">
        <button (click)="togglePlayback()" class="audio-player__mobile-play" [attr.aria-label]="isPlaying ? 'pause' : 'play'">
          <fa-icon [icon]="isPlaying ? 'pause' : 'play'"></fa-icon>
        </button>
        <button *ngIf="!hideDownloadOption" (click)="downloadFile()" class="audio-player__download" aria-label="Download audio">
          <fa-icon icon="download"></fa-icon>
        </button>
        <button *ngIf="routeToSource" (click)="goToSource()" class="audio-player__post-link" aria-label="Route to source">
          <fa-icon icon="arrow-up-right-from-square"></fa-icon>
        </button>
        <button *ngIf="playlist.length > 0" (click)="togglePlaylist()" class="audio-player__playlist-toggle" aria-label="toogle play list">
          <fa-icon icon="bars"></fa-icon>
        </button>
        <button class="audio-player__close audio-player__close--mobile" (click)="close()" aria-label="close">
          <fa-icon icon="close"></fa-icon>
        </button>
        <div *ngIf="playlist.length > 0" class="audio-player__list" [class.visible]="playlistOpen">
          <div class="audio-player__list-header">
            <p>Playlist</p>
            <myp-switch [active]="autoplay" label="Autoplay" labelColor="#fff" (toggle)="toggleAutoplay()"></myp-switch>
          </div>
          <div class="audio-player__list-contents">
            <div *ngFor="let file of playlist" class="audio-player__list-item" [class.active]="file.id == currentFile.id">
              <div *ngIf="file.id == currentFile.id && isPlaying" class="audio-player__spinner">
                <ngx-spinner name="waveform" color="#0083ff" bdColor="rgba(0,0,0,0)" [fullScreen]="false" type="line-scale-pulse-out" size="small"></ngx-spinner>
              </div>
              <p (click)="play(file)">{{ file.filename }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <audio *ngIf="audioLoaded" id="audio_player" autoplay="true">
      <source (error)="handleSourceError()" [src]="src" [type]="'audio/' + this.extension" />
    </audio>
  </div>
</div>
