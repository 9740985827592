import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ViewChildren, ElementRef, QueryList } from "@angular/core";
import { UploadWareBaseComponent } from "@mypxplat/xplat/features";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploader } from "ng2-file-upload";
import { StorageService, StorageKeys, UserService, environment, ExchangeService, BaseComponent, HelperService, WindowService } from "@mypxplat/xplat/core";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { Router } from "@angular/router";

@Component({
  selector: "myp-upload-file",
  templateUrl: "upload-file.component.html",
})
export class UploadFileComponent extends BaseComponent implements OnInit {
  @Input() uploadMethod: any;
  @Input() allowedTypes: Array<string>;
  @Input() allowedFormatString: string = ".jpg, .png";
  @Input() cropRatio: number;
  @Input() editingUserId: any;
  @Output() fileUploaded: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("cropper", { static: false }) cropper: ImageCropperComponent;
  public hasBaseDropZoneOver = false;
  public uploader: FileUploader;
  public uploadingComplete: boolean = false;
  public uploadingFile: any;
  public fileResponse: any;

  public cropperSettings: CropperSettings;
  public data: any;
  public image: any;

  @Input() pluginImageUUID: string;

  constructor(
    private _win: WindowService,
    public storageService: StorageService,
    public userService: UserService,
    public activeModal: NgbActiveModal,
    public exchangeService: ExchangeService,
    public router: Router,
    public helperService: HelperService
  ) {
    super(userService);
  }

  ngOnInit() {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.canvasWidth = 300;
    this.cropperSettings.canvasHeight = 200;
    this.cropperSettings.cropperClass = "imagecropper";
    if (this.uploadMethod == "change_profile_photo") {
      this.cropperSettings.croppedWidth = 300;
      this.cropperSettings.croppedHeight = 300;
    } else if (this.uploadMethod == "change_cover_photo") {
      this.cropperSettings.croppedWidth = 1000;
      this.cropperSettings.croppedHeight = 300;
      this.cropperSettings.width = 1000;
      this.cropperSettings.height = 300;
    } else if (this.uploadMethod == "add_learn_group_image") {
      this.cropperSettings.canvasWidth = 300;
      this.cropperSettings.canvasHeight = 169;
      this.cropperSettings.croppedWidth = 1000;
      this.cropperSettings.croppedHeight = 563;
      this.cropperSettings.width = 1000;
      this.cropperSettings.height = 563;
    }
    this.data = {};
    let additionalParams: any = {
      method: this.uploadMethod,
    };
    if (this.editingUserId) additionalParams.user_id = this.editingUserId;
    this.uploader = new FileUploader({
      isHTML5: true,
      method: "POST",
      queueLimit: 1,
      autoUpload: this.cropRatio ? false : true,
      url: environment.apiUrl + "users/upload_file",
      headers: [
        { name: "X-API-KEY", value: environment.presonusApiKey },
        { name: "X-USER-TOKEN", value: this.helperService.token },
      ],
      additionalParameter: additionalParams,
      allowedMimeType: this.allowedTypes ? this.allowedTypes : null,
    });

    this.uploader.onAfterAddingFile = (f) => {
      if (this.cropRatio) {
        this.image = new Image();
        var file: File = f._file; //e.target.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (loadEvent: any) => {
          this.image.src = loadEvent.target.result;
          this.cropper.setImage(this.image);
        };

        myReader.readAsDataURL(file);
      } else if (this.uploadMethod == "add_plugin_image") {
        let options = {
          additionalParameter: {
            uuid: this.pluginImageUUID,
            method: this.uploadMethod,
          },
        };
        this.uploader.setOptions({
          ...this.uploader.options,
          ...options,
        });
        if (!this.pluginImageUUID) {
          alert("Please enter a UUID.");
          this.uploader.clearQueue();
        }
      }

      this.uploadingFile = f;
      f.withCredentials = false;
    };
    this.uploader.onProgressAll = (progress: any) => {
      if (progress == 100) {
        this.uploadingComplete = true;
      }
    };

    this.uploader.onBeforeUploadItem = (item: any) => {
      if (this.cropRatio) {
        var dataURItoBlob = function (dataURI) {
          var binary = atob(dataURI.split(",")[1]);
          var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
          var array = [];
          for (var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
          }
          return new Blob([new Uint8Array(array)], { type: mimeString });
        };
        var blob = dataURItoBlob(this.uploadingFile);
        item._file = blob;
      }
    };

    this.uploader.response.subscribe((response) => {
      this.fileResponse = JSON.parse(response);
      this.fileUploaded.next(this.fileResponse);
      if (this.uploadMethod == "change_profile_photo" || this.uploadMethod == "change_cover_photo") {
        this.userService.getUserDetails(false).subscribe((result) => {
          if (this.userService.user.photoURL) {
            let newProfilePhoto = this.userService.user.photoURL + "";
            this.userService.user.photoURL = undefined;
            this._win.setTimeout(() => {
              this.userService.user.photoURL = newProfilePhoto;
            }, 500);
          }
          this.activeModal.close(result);
        });
      }
    });
  }

  performUpload() {
    this.uploadingFile = this.data.image;
    this.uploader.uploadAll();
  }

  fileOverBase(e) {
    this.hasBaseDropZoneOver = e;
  }
}
